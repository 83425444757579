import {combineReducers} from 'redux'
import auth from './auth'
import codes from './codes'
import config from './config'
import users from "./users"
import transactions from "./transactions";
import wallet from "./wallet";
import payouts from "./payouts";
import cards from "./cards";

export default combineReducers({
  auth,
  codes,
  config,
  users,
  transactions,
  wallet,
  payouts,
  cards
})
