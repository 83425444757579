import React, {useCallback, useEffect} from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import {List, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import {fetchConfigs, updateConfig} from '../../Reducers/config';
import ConfigItem from './ConfigItem';
import './styles.scss';

const b = b_.lock('Config');

function ConfigList() {
  const {data, update} = useSelector(state => state.config, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleConfigSave = useCallback(
    (name, value) => {
      dispatch(updateConfig({name: name, value})).then(() => {
        message.success(`${name} has been updated!`);
        fetch();
      }).catch(error => {
        console.error(error);
        message.error('Failed to update code.');
      });
    },
    [dispatch, fetch],
  );

  const isConfigLoading = useSelector(
    (state) => state.config.update.isLoading,
    shallowEqual,
  );


  return <>
    <Title className="mb" level={2}>Common</Title>
    {data.error && <ErrorBlock className="mb" message={data.error.message}/>}
    <List loading={data.isLoading || update.isLoading}
          dataSource={data.payload || []}
          bordered
          className={b('list')}
          renderItem={item => (
            <List.Item className={b('listItem')}>
              <ConfigItem fetch={fetch} item={item} handleSave={handleConfigSave} isLoading={isConfigLoading}/>
            </List.Item>
          )}
    />
  </>;
}

export default ConfigList;
