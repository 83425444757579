import {createAction, createReducer} from '@reduxjs/toolkit';
import frc from 'front-end-common';
import WalletService from "../Services/WalletService";

const {getDefaultHandler, getDefaultState} = frc.utils.reducerTools;

export const getWallet = createAction('wallet/get', (params) => ({
  payload: WalletService.getWallet(params),
}))

export const getBalances = createAction('balances/get', (params) => ({
  payload: WalletService.getBalances(params),
}))

const initState = {
  getWallet: getDefaultState(),
  balances: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(getWallet, 'getWallet'),
  ...getDefaultHandler(getBalances, 'balances'),
});
