import React from 'react';
import './styles.scss';
import ConfigList from "./ConfigList";
import ParamList from "./ParamList";
import Title from "antd/lib/typography/Title";

function Config() {

  return <>
    <Title className="mb">Config</Title>
    <ParamList/>
    <br/>
    <ConfigList/>
  </>;
}

export default Config;
