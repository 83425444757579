import React, {useCallback, useState, useEffect} from 'react';
import b_ from 'b_';
import {Col, Input, Row} from 'antd';
import './styles.scss';
import {Button} from "antd";

const b = b_.lock('TransactionFilters');

function TransactionFilters({className, filters, onFilter}) {
  const [curFilters, setCurFilters] = useState(filters);

  useEffect(() => {
    setCurFilters(filters)
  }, [filters])

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({...curFilters, [field]: preparedValue});
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} justify={'start'} className={`${className} ${b()}`}>
    <Col order={1} style={{width: 300}}>
      <span className={b('label')}>ID</span>
      <Input value={curFilters.id ? curFilters.id[0] : null}
             type="number"
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'id')}
      />
    </Col>
    <Col order={1} style={{width: 300}}>
      <span className={b('label')}>Code</span>
      <Input value={curFilters.code ? curFilters.code[0] : null}
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'code')}
      />
    </Col>
    <Col order={2} style={{width: 300}}>
      <span className={b('label')}>BTC Address</span>
      <Input value={curFilters.btcAddr ? curFilters.btcAddr[0] : null}
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'btcAddr')}
      />
    </Col>
    <Col order={3} style={{width: 200}}>
      <span className={b('label')}>Currency</span>
      <Input className={b('item')}
             allowClear
             value={curFilters.curr ? curFilters.curr[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'curr')}
      />
    </Col>
    <Col order={3} style={{width: 200}}>
      <span className={b('label')}>Comment</span>
      <Input className={b('item')}
             allowClear
             value={curFilters.comment ? curFilters.comment[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'comment')}
      />
    </Col>
    <Col gutter={2} order={5}>
      <span className={b('label')} style={{marginBottom: 0, marginTop: 8}}/>
      <Button type="primary" onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>;
}

export default TransactionFilters;
