import ApiService from './ApiService';

export default {
  getList() {
    return ApiService.get('/configs');
  },
  updateConfig(data) {
    return ApiService.post('/config', data);
  },
  getParamList() {
    return ApiService.get('/params');
  },
  updateParam(data) {
    return ApiService.post('/param', data);
  },
}
