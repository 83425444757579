import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';

const ALERT_SCOPE_TO_COLOR = {
  success: 'green',
  info: 'blue',
  warning: 'gold',
  danger: 'red'
};

function PrintUserAlert({className, alert, onClick}) {
  return <Popover content={alert.alertBody}>
    <Tag className={className} color={ALERT_SCOPE_TO_COLOR[alert.alertClass] || 'magenta'} onClick={onClick}
         style={{cursor: "pointer"}}>
      {alert.alertScope}
    </Tag>
  </Popover>
}

PrintUserAlert.propTypes = {
  className: PropTypes.string,
  alert: PropTypes.object,
  onClick: PropTypes.func,
};

PrintUserAlert.defaultProps = {
  className: '',
};

export default React.memo(PrintUserAlert);
