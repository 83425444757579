import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import './styles.scss'
import {Input, Typography} from 'antd'
import useTable from '../../Utils/useTable'
import {usersPageFetch} from '../../Reducers/users'
import UsersTable from './UsersTable'
import b_ from 'b_';
import UserFilters from "./UserFilters";
import ChangeStatusButton from "./ChangeStatusButton";
import SetMult from "./SetMult";
import SetAlert from "./SetAlert";
import AddUser from "./AddUser";
import mockUsers from "./mockUsers.json"
import { get, map } from 'lodash'

const b = b_.lock('Users');


function Users() {
  const {
    list,
    info,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    query,
    onSearch
  } = useTable(state => state.users.page, usersPageFetch);

  const usersPage = useSelector(state => state.users.page, shallowEqual)

  const [selectedRow, setSelectedRow] = useState([]);
  const [searchedData, setSearchedData] = useState({
    list,
    filtered: false,
    searchText: ""
  });

  const handleSearch = (e) => {
    const reg = new RegExp(e.target.value, "gi");
    const filteredData = map(list, (record) => {
      const nameMatch = get(record, "email").match(reg);
      if(!nameMatch) {
        return null
      }
      return record
    }).filter((record) => !!record)

    console.log('FILT', filteredData)
    setSearchedData({
      searchText: e.target.value,
      filtered: !!e.target.value,
      list: e.target.value ? filteredData : list
    })
  }
  
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  console.log(window.location)

  return (
    <>
      <Typography.Title>Users</Typography.Title>
      <div className={b('userBlock')}>
        <ChangeStatusButton name="Enable" status="Enabled" afterUpdate={fetch} selected={selectedRow}/>
        <ChangeStatusButton name="Disable" status="Disabled" afterUpdate={fetch} selected={selectedRow}/>
        <AddUser afterUpdate={fetch}/>
        <SetMult afterUpdate={fetch} selected={selectedRow}/>
        <SetAlert afterUpdate={fetch} selected={selectedRow}/>
      </div>
      <UserFilters handleSearch={handleSearch} searchedData={searchedData} filters={filters} onFilter={onFilter} className="mb"/>
      <UsersTable
        list={searchedData.searchText ? searchedData.list : list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        isLoading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        sorter={sort}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        platforms={usersPage.payload?.platforms?.map(p => {
          return {text: p, value: p}
        })
        }
        statuses={usersPage.payload?.statuses?.map(p => {
          return {text: p, value: p}
        })}
        walletCurrency={info.walletCurrency}
      />
    </>
  );
}

export default Users;
