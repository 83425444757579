import React, { useCallback } from 'react';
import {Popconfirm, message, Button} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {LoadingOutlined} from "@ant-design/icons";
import {updateUsers} from "../../../Reducers/users";

function ChangeStatusButton({ className, name, status, selected, afterUpdate }) {
  const {isLoading} = useSelector(state => state.users.updateUsers, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(updateUsers({ids: selected, status})).then(() => {
      message.success('User(s) has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('User(s) update failed');
    });
  }, [dispatch, selected, afterUpdate]);

  return (<Popconfirm title={`Are you sure you want change status of ${selected.length} users?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button
        className={className}
        icon={isLoading && <LoadingOutlined/>}
        type="primary"
        htmlType="submit"
        disabled={!selected.length}>
      {name}
    </Button>
  </Popconfirm>);
}

export default ChangeStatusButton;
