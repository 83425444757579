import ApiService from './ApiService';

const TransactionsService = {

  getTransactionsList({filters, orderPath, ...params}) {
    const updatedParams = {...params};

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/transactions', updatedParams);
  },
  addTransaction(data) {
    return ApiService.post('/transaction', data);
  },
  getAddPageData(data) {
    return ApiService.get('/transaction/add', data);
  },

};

export default TransactionsService