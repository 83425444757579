import React from 'react';
import { Row, Col } from 'antd';
import { JSONTree } from 'react-json-tree';
import theme from '../../../Utils/jsonTheme';

function Expandable({ className, record }) {
  const { resultDetails } = record;

  return <Row className={className} gutter={16}>
    <Col span={8}>
      <b className="block mb">Result Details</b>
      <JSONTree hideRoot theme={theme} data={resultDetails} shouldExpandNode={() => true} />
    </Col>
  </Row>
}

export default Expandable;
