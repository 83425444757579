import React, {useCallback, useEffect} from 'react';
import {Button, Drawer, Form, Input, message, Popover, Select} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {EditTwoTone, LoadingOutlined} from "@ant-design/icons";
import {getUpdatePageData, updateUsers} from "../../../Reducers/users";

const useBoolean = frc.hooks.useBoolean;

function SetMult({className, afterUpdate, selected, iconClassName, isCell}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {payload: pageData} = useSelector(state => state.users.getUpdatePageData, shallowEqual);
  const {error, isLoading} = useSelector(state => state.users.updateUsers, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    dispatch(
      updateUsers({
        ids: selected,
        mult: {platform: data.platform, group: data.group, value: data.mult ? data.mult / 100 : ""}
      })
    ).then(() => {
      message.success('User mult updated!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to update user mult.');
    });
  }, [dispatch, selected, close]);

  useEffect(() => {
    dispatch(getUpdatePageData())
  }, [dispatch, close])


  const [form] = Form.useForm();

  const onPlatformChange = (value) => {
    form.resetFields(['group'])
  }


  return <>
    {isCell ? (
        <Popover content={'Edit'}>
          <EditTwoTone
            className={iconClassName}
            style={{
              position: 'absolute',
              top: '50%',
              left: '15px',
              transform: 'translateY(-50%)',
            }}
            onClick={open}
          />
        </Popover>
    ) : (
      <Button
        className={className}
        icon={isLoading && <LoadingOutlined/>}
        type="primary"
        htmlType="submit"
        disabled={!selected.length}
        onClick={open}>
        Set Mult
      </Button>
    )}
    <Drawer onClose={close}
            visible={isOpen}
            title="Set Mult"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="Mult" name="mult"><Input addonAfter="%" type={"number"}/></Form.Item>
        <Form.Item
          rules={[{required: true, message: 'Please select platform'}]}
          label="Platform"
          name="platform"
        >
          <Select style={{width: '100%'}} onSelect={onPlatformChange}>
            {pageData?.platformGroups && Object.keys(pageData.platformGroups).sort().map(platform => <Select.Option
              key={platform}
              value={platform}>{platform}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (<Form.Item label="Group" name="group">
              <Select style={{width: '100%'}}>
                {pageData?.platformGroups && form.getFieldValue('platform')
                  && pageData.platformGroups[form.getFieldValue('platform')].map(group =>
                    <Select.Option key={group} value={group}>{group}</Select.Option>)
                }</Select>
            </Form.Item>)
          }}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Set </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default SetMult;
