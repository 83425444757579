import React, {useCallback, useEffect, useState} from 'react'
import {Table} from 'antd'
import b_ from 'b_'
import TableSettings from "../../Components/TableSettings"
import useStorageState from '../../Utils/useStorageState'
import PrintTransactionState from "../../Components/Prints/PrintTransactionState";
import {useDispatch} from "react-redux";
import PrintDate from "../../Components/Prints/PrintDate";
import {Link} from "react-router-dom";

const b = b_.lock('TransactionsTable')

function TransactionsTable({
                             list,
                             tableOnChange,
                             pagination,
                             isLoading,
                             afterUpdate,
                             onFilter,
                             filters,
                             sorter,
                             selected,
                             onSelectChange
                           }) {

  const dispatch = useDispatch();

  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        sorter,
        sortOrder: sorter.field === 'id' ? sorter.order : null,
      },
      {
        title: 'Time',
        dataIndex: 'ts',
        key: 'ts',
        sorter,
        sortOrder: sorter.field === 'ts' ? sorter.order : null,
        render: (ts) => <PrintDate withTime date={ts}/>,
      },
      {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
        width: 100,
      },
      {
        title: 'Code/Payout',
        dataIndex: 'code',
        key: 'code',
        render: (code, rec) =>
          code ? <Link to={`/?limit=50&offset=0&filters[code][]=${code}`}>{code}</Link> :
            rec.btcAddr && <a href={`https://blockchain.info/address/${rec.btcAddr}`} rel="noreferrer noopener"
               target="_blank">{rec.btcAddr}</a>
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 80,
        align: "right",
        render: (amount) => amount && amount.toFixed(2)
      },
      {
        title: 'Currency',
        dataIndex: 'curr',
        key: 'curr',
        sorter,
        sortOrder: sorter.field === 'curr' ? sorter.order : null
      },
      {
        title: "State",
        dataIndex: "state",
        align: "center",
        width: 150,
        filters: [
          {text: 'Pending', value: 'PENDING'},
          {text: 'Cancelled', value: 'CANCELLED'},
          {text: 'Finished', value: 'FINISHED'},
        ],
        filterMultiple: false,
        filteredValue: filters['state'] || null,
        render: (state) => <PrintTransactionState state={state}/>
      },
      {
        title: 'Referral Tx',
        dataIndex: 'referralTxId',
        key: 'referralTxId',
        width: 100,
        render: (referralTxId, rec) =>
            referralTxId ? <Link to={`/transactions?limit=50&offset=0&filters[userEmail][]=${rec.referralEmail}&filters[id][]=${rec.referralTxId}`}>{referralTxId}</Link> : ""
      },
      {
        title: "Comment",
        dataIndex: "comment",
        width: 170,
        sorter,
        sortOrder: sorter.field === 'comment' ? sorter.order : null,
      },
    ];
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('transactions_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
        allCheckList.push(e[Object.keys(e)[1]])
        setCheckbox(allCheckList)
      })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumns().map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }, [Checkboxs]);

  useEffect(() => {
    setColumns(GetColumns())
  }, [list, isLoading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading
  return <div className={b()}>
    {/*<CodeFilters filters={filters} onFilter={onFilter} className="mb"/>*/}
    <Table
      className={b()}
      bordered
      dataSource={list}
      scroll={{y: "calc(100vh - 125px)", x: "max-content"}}
      size="middle"
      onChange={(a, b, c) => tableOnChange(a, b, c)}
      onFilter={onFilter}
      pagination={pagination}
      loading={isLoading}
      columns={columnsFilter}
      rowClassName={() => b('lock')}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
      }}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
      />}
    />
  </div>
}

export default TransactionsTable;
