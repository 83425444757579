import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {cancel} from "../../../Reducers/codes";
import {LoadingOutlined} from "@ant-design/icons";

const useBoolean = frc.hooks.useBoolean;

function Cancel({className, codeId, afterUpdate}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.codes.cancel, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    dispatch(cancel(codeId)).then(() => {
      message.success('Code has been cancelled!');
      close();
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to cancel code.');
    });
  }, [dispatch, codeId, close]);

  return <>
    <Button className={className} type="link" size="small" onClick={open}>Cancel</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Cancel"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            {required: true, pattern: 'cancel', message: 'Enter "cancel" to confirm.'}
          ]}
          label="Enter 'cancel' to confirm."
          name="confirm"
        >
          <Input type="text"/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Confirm </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default Cancel;
