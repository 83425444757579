import React, {useCallback, useEffect} from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import {List, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import {fetchParams, updateParam} from '../../Reducers/config';
import './styles.scss';
import ConfigItem from "./ConfigItem";

const b = b_.lock('Config');

function ParamList() {
  const {paramData, paramUpdate} = useSelector(state => state.config, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchParams());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleParamSave = useCallback(
    (name, value) => {
      dispatch(updateParam({name: name, value})).then(() => {
        message.success(`${name} has been updated!`);
        fetch();
      }).catch(error => {
        console.error(error);
        message.error('Failed to update code.');
      });
    },
    [dispatch, fetch],
  );

  const isParamLoading = useSelector(
    (state) => state.config.paramUpdate.isLoading,
    shallowEqual,
  );


  return <>
    <Title className="mb" level={2}>Payout</Title>
    {paramData.error && <ErrorBlock className="mb" message={paramData.error.message}/>}
    <List loading={paramData.isLoading || paramUpdate.isLoading}
          dataSource={paramData.payload || []}
          bordered
          className={b('list')}
          renderItem={item => (
            <List.Item className={b('listItem')}>
              <ConfigItem fetch={fetch} item={item} handleSave={handleParamSave} isLoading={isParamLoading}/>
            </List.Item>
          )}
    />
  </>;
}

export default ParamList;
