import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import ConfigService from '../Services/ConfigService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchConfigs = createAction('config/get', () => ({
  payload: ConfigService.getList(),
}));

export const updateConfig = createAction('config/update', (data) => ({
  payload: ConfigService.updateConfig(data),
}));

export const fetchParams = createAction('params/get', () => ({
  payload: ConfigService.getParamList(),
}));

export const updateParam = createAction('param/update', (data) => ({
  payload: ConfigService.updateParam(data),
}));

const initState = {
  data: getDefaultState(),
  update: getDefaultState(),
  paramData: getDefaultState(),
  paramUpdate: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchConfigs, 'data'),
  ...getDefaultHandler(updateConfig, 'update'),
  ...getDefaultHandler(fetchParams, 'paramData'),
  ...getDefaultHandler(updateParam, 'paramUpdate'),
});
