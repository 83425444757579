import React, {useEffect} from 'react';
import b_ from 'b_';
import './styles.scss';
import Table from 'antd/es/table/Table';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {getWallet} from "../../../Reducers/wallet";

const b = b_.lock('WalletTable');


function WalletTable({className, userEmail}) {
  const {payload, isLoading} = useSelector(state => state.wallet.getWallet, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWallet({userEmail}));
  }, [dispatch]);

  return (<Table bordered
                 className={b('table')}
                 pagination={false}
                 dataSource={payload && payload.currencies}
                 loading={isLoading}
                 size="small"
                 scroll={{x: "max-content"}}
                 summary={(data) => payload &&
                   <Table.Summary.Row>
                     <Table.Summary.Cell index={0} colSpan={3} align="right">Total:</Table.Summary.Cell>
                     <Table.Summary.Cell index={3} align="right">
                       {`${payload.usdTotalActual} (${payload.usdTotalPending})`}
                     </Table.Summary.Cell>
                   </Table.Summary.Row>
                 }
  >
    <Table.Column align={"left"} dataIndex="currency" title="Currency"/>
    <Table.Column align={"right"} dataIndex="balanceActual" title="Balance" width={120}
                  render={(value, rec) => `${value} (${rec.balancePending})`}/>
    <Table.Column align={"right"} dataIndex="usdRate" title={payload?.currency + " Rate"} width={120}/>
    <Table.Column align={"right"} dataIndex="usdBalanceActual" title={"Balance, " + payload?.currency} width={120}
                  render={(value, rec) => `${value} (${rec.usdBalancePending})`}/>
  </Table>);
}

export default WalletTable;
