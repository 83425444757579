import React, {useState} from 'react'
import './styles.scss'
import {Typography} from 'antd'
import useTable from '../../Utils/useTable'
import {transactionsPageFetch} from '../../Reducers/transactions'
import TransactionsTable from './TransactionsTable'
import b_ from 'b_';
import TransactionFilters from "./TransactionsFilters";
import AddTransaction from "./AddTransaction";
import Title from "antd/lib/typography/Title";
import WalletTable from "./WalletTable";
import {Link} from "react-router-dom";

const b = b_.lock('Transactions');


function Transactions() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    query,
    onSearch
  } = useTable(state => state.transactions.page, transactionsPageFetch);

  // const transactionsPage = useSelector(state => state.transactions.page, shallowEqual)

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const userEmail = filters.userEmail ? filters.userEmail[0] : null

  return (
    <>
      <Title level={2}>User: <Link to={`/users?limit=50&offset=0&filters[email][]=${userEmail}`}>{userEmail}</Link></Title>
      <Title level={2}>Wallet</Title>
      <div style={{marginBottom: "20px"}}>
        <WalletTable className="mb" userEmail={userEmail}/>
      </div>
      <Title level={2}>Transactions</Title>
      <div className={b('transactionBlock')}>
        <AddTransaction afterUpdate={fetch} userEmail={userEmail}/>
      </div>
      <TransactionFilters filters={filters} onFilter={onFilter} className="mb"/>
      <TransactionsTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        isLoading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        sorter={sort}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </>
  );
}

export default Transactions;
