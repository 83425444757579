import ApiService from './ApiService';

const WalletService = {

  getWallet(params) {
    return ApiService.get('/wallet', params);
  },

  getBalances(params) {
    return ApiService.get('/balances', params);
  },
};

export default WalletService