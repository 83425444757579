import React, { useState } from 'react';
import { Button, Checkbox, Col, Row, Typography } from 'antd';
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;

function TableSettings({ allChecks, onChangeCheckAll, setCheckbox, Checkboxs, TableColumn }) {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  let nameCheckList = [];
  let nameField = '';
  TableColumn().filter((e) => {
    for (nameField in e) {
      if (nameField === 'title') {
        nameCheckList.push(e[nameField]);
        return
      }
    }
  });

  return (
    <>
      {!isOpen && (
        <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
          Open table settings
        </Button>
      )}
      {isOpen && (
        <>
          <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
            Close table settings
          </Button>
          <Title level={3}>Select Columns</Title>
          <Row className="mb">
            <Checkbox indeterminate={allChecks.indeterminate} onChange={onChangeCheckAll} checked={allChecks.checkAll}>
              Select All
            </Checkbox>
          </Row>
          <Row>
            <CheckboxGroup value={Checkboxs} onChange={(e) => setCheckbox(e)} className="checksBoxGroup">
              {nameCheckList.map((item, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col
                  key={key}
                  className="mb-small"
                  style={{width:20, height: 20}}
                  span={8}
                >
                  <Checkbox value={item}>{item}</Checkbox>
                </Col>
              ))}
            </CheckboxGroup>
          </Row>
        </>
      )}
    </>
  );
}

export default TableSettings;
