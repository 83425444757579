import React, {useCallback, useEffect} from 'react';
import {Button, Drawer, Form, Input, message, Select} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {addTransaction, getAddPageData} from "../../../Reducers/transactions";

const useBoolean = frc.hooks.useBoolean;

function AddTransaction({className, afterUpdate, userEmail}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {payload: pageData} = useSelector(state => state.transactions.getAddPageData, shallowEqual);
  const {error, isLoading} = useSelector(state => state.transactions.addTransaction, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    dispatch(
      addTransaction({userEmail, ...data})
    ).then(() => {
      message.success('Transaction added!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to add transaction.');
    });
  }, [dispatch, close]);

  useEffect(() => {
    dispatch(getAddPageData())
  }, [dispatch, close])

  const [form] = Form.useForm();

  return <>
    <Button
      className={className}
      icon={isLoading && <LoadingOutlined/>}
      type="primary"
      htmlType="submit"
      onClick={open}>
      Add Transaction
    </Button>
    <Drawer onClose={close}
            visible={isOpen}
            title={`Add Transaction - ${userEmail}`}
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="Amount" name="amount" rules={[{required: true}]}><Input type={"number"}/></Form.Item>
        <Form.Item rules={[{required: true, message: 'Please select currency'}]} label="Currency" name="curr">
          <Select style={{width: '100%'}}>
            {pageData?.currencies && [...pageData.currencies].sort().map(curr =>
              <Select.Option key={curr} value={curr}>{curr}</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Comment" name="comment"><Input/></Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Add </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default AddTransaction;
