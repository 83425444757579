import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {useLocation, useNavigate, useParams, Link} from 'react-router-dom';
import b_ from "b_";
import './styles.scss';
import {MenuUnfoldOutlined, MenuFoldOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {history} from "../../../Boots/createStore";
import {links} from "../../../Boots/Routes";
import Logout from '../../Logout';
import Balances from "./Balances";
import {getDomainUrl, getTitle, isClapswap} from "../../../Utils/envDependent";
import useMediaQuery from '../../../Utils/useMediaQuery';
import MobMenu from './MobMenu';
import fec from 'front-end-common';

const b = b_.lock('Header')

const AntHeader = Layout.Header;
const {SubMenu} = Menu;
const {Sider} = Layout;

const IconType = ({state}) => {
  return (
    <Button type="primary">
      {state ? <MenuUnfoldOutlined style={{fontSize: 20}}/> : <MenuFoldOutlined style={{fontSize: 20}}/>}
    </Button>
  )
}

function Header({className}) {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const [icon, setIcon] = useState(false)
  const path = links.filter((link) => history.location.pathname === link.href)

  const platforms = fec.utils.platformsData;
  const showOtherPlatforms = !isClapswap();

  console.log('showOtherPlatforms', showOtherPlatforms)

  const media890 = useMediaQuery('(max-width: 1480px)')

  const navItems = platforms.map((link) => {
    if (showOtherPlatforms || link.key === 'kodo') {
      return {
        label: (
          <a href={link.link}>{link.name}</a>
        ),
        key: link.key,
      };
    }
    return null;
  }).filter(Boolean);

  return (
    <AntHeader className={`${b()} ${className}`} router={{location, navigate, params}}>
      <Sider width="63px" className="Menu">
        <Menu
          mode="vertical"
          forceSubMenuRender={false}
          defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          defaultOpenKeys={(path.length && [path[0].href]) || ''}
          onOpenChange={() => setIcon(!icon)}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{fontSize: 15}}
            title={<IconType state={icon}/>}
          >
            {links.map((link) => (
              link.external ? (
                <Menu.Item key={link.href}>
                  <a href={link.href} rel="noreferrer noopener" target="_blank">
                    {link.icon}
                    <span>{link.title}</span>
                  </a>
                </Menu.Item>) : (
                <Menu.Item key={link.href}>
                  <Link to={link.href}>
                    {link.icon}
                    <span>{link.title}</span>
                  </Link>
                </Menu.Item>
              )
            ))}
          </SubMenu>
        </Menu>
      </Sider>
      {media890 ? (
      <>
      <Balances/>
      <MobMenu items={navItems} />
      </>
      ) : (
        <>
      <Menu
        defaultSelectedKeys={['kodo']}
        mode="horizontal"
        className="Nav"
      >
        {platforms?.map((link) => (
          showOtherPlatforms || link.key === "kodo" ? (
            <Menu.Item key={link.key}>
              <a href={link.link}>{link.name}</a>
            </Menu.Item>
          ) : null
        ))}
      </Menu>
      <Balances/>
      </>
      )}
      
      <div className="Logout">
        <Logout/>
      </div>
    </AntHeader>
  );
}

export default React.memo(Header);
