import b_ from 'b_';
import React from 'react';
import cx from '../../Utils/cx';
import './styles.scss';

const b = b_.lock('AuthLayout');

function AuthLayout({ children, className }) {
  return (
    <div className={b()}>
      <div className={cx(b('inner'), className)}>{children}</div>
    </div>
  );
}

export default AuthLayout;
