import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';

const CODE_STATUS_TO_COLOR = {
  Done: 'green',
  Error: 'red',
};

function PrintCodeStatus({className, codeStatus}) {
  return <Tag className={className} color={CODE_STATUS_TO_COLOR[codeStatus] || 'yellow'}>
    {codeStatus.toLowerCase()}
  </Tag>;
}

PrintCodeStatus.propTypes = {
  className: PropTypes.string,
  codeStatus: PropTypes.string,
};

PrintCodeStatus.defaultProps = {
  className: '',
};

export default React.memo(PrintCodeStatus);
