import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import ErrorBlock from '../../../Components/ErrorBlock';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {validate} from "../../../Reducers/codes";
import {LoadingOutlined} from "@ant-design/icons";

const useBoolean = frc.hooks.useBoolean;

function Validate({className, codeId, afterUpdate}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.codes.validate, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    dispatch(validate(codeId)).then(() => {
      message.success('Code has been validated!');
      close();
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to validate code.');
    });
  }, [dispatch, codeId, close]);

  return <>
    <Button className={className} type="link" size="small" onClick={open}>Validate</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Validate"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            {required: true, pattern: 'validate', message: 'Enter "validate" to confirm.'}
          ]}
          label="Enter 'validate' to confirm."
          name="confirm"
        >
          <Input type="text"/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined />}> Confirm </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default Validate;
