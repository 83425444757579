import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';

const TX_STATE_TO_COLOR = {
  FINISHED: 'green',
  PENDING: 'blue',
  CANCELLED: 'yellow'
};

function PrintTransactionState({className, state}) {
  return <Tag className={className} color={TX_STATE_TO_COLOR[state] || 'red'}>
    {state.charAt(0) + state.slice(1).toLowerCase()}
  </Tag>;
}

PrintTransactionState.propTypes = {
  className: PropTypes.string,
  state: PropTypes.string,
};

PrintTransactionState.defaultProps = {
  className: '',
};

export default React.memo(PrintTransactionState);
