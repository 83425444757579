import React, {useCallback, useEffect} from 'react';
import {Button, Drawer, Form, Input, message, Popover, Select} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {EditTwoTone, LoadingOutlined} from "@ant-design/icons";
import {updateUsers} from "../../../Reducers/users";
import PrintUserAlert from "../../../Components/Prints/PrintUserAlert";
import {getSubmitPageData} from "../../../Reducers/codes";

const useBoolean = frc.hooks.useBoolean;

function SetAlert({className, afterUpdate, selected, iconClassName, isCell, alert}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.users.updateUsers, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFields([{name: "class", value: alert?.alertClass}])
  }, [alert?.alertClass])

  const handleSuccess = useCallback((data) => {
    dispatch(
      updateUsers({
        ids: selected,
        alert: {class: data.class, body: data.body}
      })
    ).then(() => {
      message.success('Alert updated!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to update alert.');
    });
  }, [dispatch, selected, close]);

  const [form] = Form.useForm();

  return <>
    {isCell ? (
      <>
        {alert.alertBody && <PrintUserAlert alert={alert} onClick={open}/>}
        <span style={{
          cursor: 'pointer',
          position: 'relative',
          height: '100%',
        }}>
        <Popover content={'Edit'}>
          <EditTwoTone
            className={iconClassName}
            // style={{
            //   position: 'absolute',
            //   top: '50%',
            //   left: 'calc(100%)',
            //   transform: 'translateY(-50%)',
            // }}
            onClick={open}
          />
        </Popover>
        </span>
      </>
    ) : (
      <Button
        className={className}
        icon={isLoading && <LoadingOutlined/>}
        type="primary"
        htmlType="submit"
        onClick={open}>
        Set Alert
      </Button>
    )}
    <Drawer onClose={close}
            visible={isOpen}
            title="Set Alert"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="Body" name="body"><Input.TextArea addonAfter="HTML"/></Form.Item>
        <Form.Item
          rules={[{required: true, message: 'Please select class'}]}
          label="Class"
          name="class"
        >
          <Select style={{width: '100%'}}>
            <Select.Option key="success" value="success">Success</Select.Option>)
            <Select.Option key="info" value="info">Info</Select.Option>)
            <Select.Option key="warning" value="warning">Warning</Select.Option>)
            <Select.Option key="danger" value="danger">Danger</Select.Option>)
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Set </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default SetAlert;
