import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';

const STATE_TO_COLOR = {
  C: 'yellow',
  E: 'red',
  P: 'blue',
  V: 'green',
  N: 'blue'
};

const STATE_TO_TEXT = {
  C: 'Canceled',
  E: 'Error',
  P: 'Processing',
  V: 'Done',
  N: 'New'
};

function PrintPayoutState({className, state}) {
  return <Tag className={className} color={STATE_TO_COLOR[state] || 'red'}>
    {STATE_TO_TEXT[state]}
  </Tag>;
}

PrintPayoutState.propTypes = {
  className: PropTypes.string,
  state: PropTypes.string,
};

PrintPayoutState.defaultProps = {
  className: '',
};

export default React.memo(PrintPayoutState);
