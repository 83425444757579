import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {updateUsers} from "../../../Reducers/users";

const useBoolean = frc.hooks.useBoolean;

function SetPassword({className, userId, afterUpdate, isPayout}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.users.updateUsers, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback((data) => {
    dispatch(
      updateUsers({
        ids: [userId],
        password: isPayout === false ? (data.password || "") : null,
        payoutPassword: isPayout === true ? (data.password || "") : null
      })
    ).then(() => {
      message.success('Password updated!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to update password.');
    });
  }, [dispatch, userId, close]);

  function generatePassword() {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    const string_length = 12;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    form.setFields([{name: "password", value: randomstring}])
  }

  return <>
    <Button className={className} type="link" size="small"
            onClick={open}>{isPayout ? "Set Payout Pass" : "Set Password"}</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title={isPayout ? "Set Payout Pass" : "Set Password"}
            placement="right"
            width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="Password" name="password" rules={[{required: !isPayout}]}>
          <Input type="text"/>
        </Form.Item>
        <Form.Item>
          <Button onClick={generatePassword} style={{marginRight: 10}}> Generate </Button>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Set </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default SetPassword;
