import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';

const STATUS_TO_COLOR = {
  Processing: 'blue',
  Done: 'green',
  Error: 'red',
};

function PrintSendStatus({className, status}) {
  return <Tag className={className} color={STATUS_TO_COLOR[status] || 'red'}>
    {status}
  </Tag>;
}

PrintSendStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
};

PrintSendStatus.defaultProps = {
  className: '',
};

export default React.memo(PrintSendStatus);
