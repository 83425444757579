import React from 'react';
import { Row, Col } from 'antd';
import { JSONTree } from 'react-json-tree';
import theme from '../../../Utils/jsonTheme';
import { Link } from 'react-router-dom';

function Expandable({ className, record }) {
  const { referees } = record;

  return <Row className={className} gutter={16}>
    <Col span={8}>
      <b className="block mb">Referees</b>
      {referees?.map((refer) => <Link to={`/?limit=50&offset=0&filters[userEmail][]=${refer.email}`}>{refer.email}<br/></Link>)}
      {/* <span>{refer.id}. {refer.email}<br/></span> */}
      {/* <JSONTree hideRoot theme={theme} data={resultDetails} shouldExpandNode={() => true} /> */}
    </Col>
  </Row>
}

export default Expandable;
