import React, {useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import './styles.scss'
import {Typography} from 'antd'
import useTable from '../../Utils/useTable'
import CardsTable from './CardsTable'
import b_ from 'b_';
import {getCardsPage} from "../../Reducers/cards";
import AddEditCard from "./AddEditCard";

const b = b_.lock('Cards');


function Cards() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    query,
    onSearch
  } = useTable(state => state.cards.cardsPage, getCardsPage);

  const cardsPage = useSelector(state => state.cards.cardsPage, shallowEqual)

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  return (
    <>
      <Typography.Title>Cards</Typography.Title>
      <div className={b('cardBlock')}>
        <AddEditCard add={true} afterUpdate={fetch} platforms={cardsPage.payload?.platforms}/>
      </div>
      <CardsTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        sorter={sort}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        platformsMap={cardsPage.payload?.platforms}
      />
    </>
  );
}

export default Cards;
