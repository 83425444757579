import React, {useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import './styles.scss'
import {Input, Typography} from 'antd'
import useTable from '../../Utils/useTable'
import {codesPageFetch} from '../../Reducers/codes'
import CodesTable from './CodesTable'
import Submit from "./Submit";
import b_ from 'b_';

const b = b_.lock('Codes');


function Codes() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.codes.page, codesPageFetch);

  const codesPage = useSelector(state => state.codes.page, shallowEqual)

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  return (
    <>
      <Typography.Title>Codes</Typography.Title>
      <div className={b('codeBlock')}>
        <Submit afterUpdate={fetch}/>
      </div>
      <CodesTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        platforms={codesPage.payload?.platforms?.map(p => {
          return {text: p, value: p}
        })
        }
        statuses={codesPage.payload?.statuses?.map(p => {
          return {text: p, value: p}
        })}
      />
    </>
  );
}

export default Codes;
