import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {payoutCancel} from "../../../Reducers/payouts";

const useBoolean = frc.hooks.useBoolean;

function Cancel({className, payoutId, afterUpdate}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.payouts.payoutCancel, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback(() => {
    dispatch(
      payoutCancel({payoutId: payoutId})
    ).then(() => {
      message.success('Success!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to cancel.');
    });
  }, [dispatch, payoutId, close]);

  return <>
    <Button className={className} type="link" size="small"
            onClick={open}>Cancel</Button>
    <Drawer onClose={close} visible={isOpen} title="Confirm Cancel" placement="right" width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Confirm </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default Cancel;
