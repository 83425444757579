import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {addUser} from "../../../Reducers/users";

const useBoolean = frc.hooks.useBoolean;

function AddUser({className, afterUpdate}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.users.addUser, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback((data) => {
    dispatch(
      addUser(data)
    ).then(() => {
      message.success('User added!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to add user.');
      afterUpdate()
    });
  }, [dispatch, close]);

  function generatePassword() {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    const string_length = 12;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    form.setFields([{name: "password", value: randomstring}])
  }

  return <>
    <Button
      className={className}
      icon={isLoading && <LoadingOutlined/>}
      type="primary"
      htmlType="submit"
      onClick={open}>
      Add
    </Button>
    <Drawer onClose={close}
            visible={isOpen}
            title={"Add User"}
            placement="right"
            width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="Login" name="login" rules={[{required: true}]}>
          <Input type="text"/>
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{required: true}]}>
          <Input type="text"/>
        </Form.Item>
        <Form.Item label="BTC Wallet" name="btcAddr">
          <Input type="text"/>
        </Form.Item>
        <Form.Item>
          <Button onClick={generatePassword} style={{marginRight: 10}}> Generate </Button>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Add </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default AddUser;
