import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {updateUsers} from "../../../Reducers/users";

const useBoolean = frc.hooks.useBoolean;

function EditReferrer({className, userId, afterUpdate, isPayout}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.users.updateUsers, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback((data) => {
    dispatch(
      updateUsers({
        ids: [userId],
        referrer: (data.referrer || ""),
      })
    ).then(() => {
      message.success('Referrer updated!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to update referrer.');
    });
  }, [dispatch, userId, close]);

  function generateReferrer() {
    const chars = "0123456789";
    const string_length = 7;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    console.log('randomstring', randomstring)
    const result = (randomstring - userId).toString()
    form.setFields([{name: "referrer", value: result}])
  }

  return <>
    <Button className={className} type="link" size="small"
            onClick={open}>Set Referrer ID</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Set Referral ID"
            placement="right"
            width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item label="referrer" name="referrer">
          <Input type="text"/>
        </Form.Item>
        <Form.Item>
          <Button onClick={generateReferrer} style={{marginRight: 10}}> Generate </Button>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Set </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default EditReferrer;
