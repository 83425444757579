import React, {useCallback, useState, useEffect} from 'react';
import b_ from 'b_';
import {Button, Col, Input, Row} from 'antd';
import './styles.scss';

const b = b_.lock('PayoutFilters');

function PayoutFilters({className, filters, onFilter}) {
  const [curFilters, setCurFilters] = useState(filters);

  useEffect(() => {
    setCurFilters(filters)
  }, [filters])

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({...curFilters, [field]: preparedValue});
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} justify={'start'} className={`${className} ${b()}`}>
    <Col order={1} style={{width: 200}}>
      <span className={b('label')}>User</span>
      <Input value={curFilters.userEmail ? curFilters.userEmail[0] : null}
             allowClear
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'userEmail')}
      />
    </Col>
    <Col gutter={2} order={5}>
      <span className={b('label')} style={{marginBottom: 0, marginTop: 8}}/>
      <Button type="primary" onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>;
}

export default PayoutFilters;
