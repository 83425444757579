import {createAction, createReducer} from '@reduxjs/toolkit';
import frc from 'front-end-common';
import TransactionsService from "../Services/TransactionsService";

const {getDefaultHandler, getDefaultState} = frc.utils.reducerTools;

export const transactionsPageFetch = createAction('transactions/page', (params) => ({
  payload: TransactionsService.getTransactionsList(params),
}))

export const addTransaction = createAction('transaction/add', (data) => ({
  payload: TransactionsService.addTransaction(data),
}))

export const getAddPageData = createAction('transaction/add-page-data', (data) => ({
  payload: TransactionsService.getAddPageData(data),
}))

const initState = {
  page: getDefaultState(),
  addTransaction: getDefaultState(),
  getAddPageData: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(transactionsPageFetch, 'page'),
  ...getDefaultHandler(addTransaction, 'addTransaction'),
  ...getDefaultHandler(getAddPageData, 'getAddPageData')
});
