import ApiService from './ApiService';

const PayoutsService = {

  getPayoutsPage({filters, orderPath, ...params}) {
    const updatedParams = {...params};

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/payouts', updatedParams);
  },
  payoutDone(data) {
    return ApiService.post('/payout/done', data);
  },
  payoutRetry(data) {
    return ApiService.post('/payout/retry', data);
  },
  payoutCancel(data) {
    return ApiService.post('/payout/cancel', data);
  },

};

export default PayoutsService