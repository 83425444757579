import {createAction, createReducer} from '@reduxjs/toolkit';
import frc from 'front-end-common';
import PayoutsService from "../Services/PayoutsService";

const {getDefaultHandler, getDefaultState} = frc.utils.reducerTools;

export const payoutsPageFetch = createAction('payouts/page', (params) => ({
  payload: PayoutsService.getPayoutsPage(params),
}))

export const payoutDone = createAction('payout/done', (data) => ({
  payload: PayoutsService.payoutDone(data),
}))

export const payoutRetry = createAction('payout/retry', (data) => ({
  payload: PayoutsService.payoutRetry(data),
}))

export const payoutCancel = createAction('payout/cancel', (data) => ({
  payload: PayoutsService.payoutCancel(data),
}))


const initState = {
  payoutsPageFetch: getDefaultState(),
  payoutDone: getDefaultState(),
  payoutRetry: getDefaultState(),
  payoutCancel: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(payoutsPageFetch, 'payoutsPageFetch'),
  ...getDefaultHandler(payoutDone, 'payoutDone'),
  ...getDefaultHandler(payoutRetry, 'payoutRetry'),
  ...getDefaultHandler(payoutCancel, 'payoutCancel'),
});
