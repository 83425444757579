import React, {useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import './styles.scss'
import {Input, Typography} from 'antd'
import useTable from '../../Utils/useTable'
import {payoutsPageFetch} from '../../Reducers/payouts'
import PayoutsTable from './PayoutsTable'
import b_ from 'b_';
import PayoutFilters from "./PayoutFilters";

const b = b_.lock('Payouts');


function Payouts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    query,
    onSearch
  } = useTable(state => state.payouts.payoutsPageFetch, payoutsPageFetch);

  const payoutsPage = useSelector(state => state.payouts.payoutsPageFetch, shallowEqual)

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  return (
    <>
      <Typography.Title>Payouts</Typography.Title>
      <PayoutFilters filters={filters} onFilter={onFilter} className="mb"/>
      <PayoutsTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        isLoading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        sorter={sort}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </>
  );
}

export default Payouts;
