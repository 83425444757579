import React, {useCallback} from 'react';
import {Button, Checkbox, Drawer, Form, Input, message, Select} from 'antd';
import ErrorBlock from '../../../Components/ErrorBlock';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getSubmitPageData, submit} from "../../../Reducers/codes";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const useBoolean = frc.hooks.useBoolean;

function Submit({className, afterUpdate}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {payload: pageData} = useSelector(state => state.codes.getSubmitPageData, shallowEqual);
  const {isLoading, payload: submitResult} = useSelector(state => state.codes.submit, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSuccess = useCallback((data) => {
    let toSubmit = {
      ...data,
      codes: data.codesText.split(/\r?\n/),
      customRate: data.customRate / 100,
      codesText: null
    }
    dispatch(submit(toSubmit)).then((result) => {
      if (result.showError === null) {
        message.success('Code has been submitted!');
        form.resetFields()
        close();
        navigate(`/?filters[packId][]=${result.codePackId}`)
      }
    }).catch(error => {
      console.error(error);
      message.error('Failed to submit code.');
    });
  }, [dispatch, close]);

  useEffect(() => {
    dispatch(getSubmitPageData())
  }, [dispatch, close])


  const [form] = Form.useForm();

  const onPlatformChange = (value) => {
    form.resetFields(['group'])
  }

  return <>
    <Button className={className} onClick={open} icon={<PlusOutlined/>} type="primary"> Submit </Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Submit"
            placement="right"
            width={600}
    >
      {submitResult?.showError && <ErrorBlock message={submitResult.showError}/>}
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item
          rules={[{required: true, message: 'Please select platform'}]}
          label="Platform"
          name="platform"
        >
          <Select style={{width: '100%'}} onSelect={onPlatformChange}>
            {pageData?.platformGroups && Object.keys(pageData.platformGroups).sort().map(platform => <Select.Option
              key={platform}
              value={platform}>{platform}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (<Form.Item rules={[{required: true, message: 'Please select group'}]}
                               label="Group"
                               name="group">
              <Select style={{width: '100%'}}>
                {pageData?.platformGroups && form.getFieldValue('platform')
                  && pageData.platformGroups[form.getFieldValue('platform')].map(group =>
                    <Select.Option key={group} value={group}>{group}</Select.Option>)
                }</Select>
            </Form.Item>)
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return form.getFieldValue('platform') && form.getFieldValue('platform').startsWith("R-") && (
              <Form.Item label="Check Only"
                         name="checkFlow"
                         valuePropName="checked">
                <Checkbox style={{width: '100%'}}/>
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return pageData?.showCustomRate && !form.getFieldValue('checkFlow') &&
              <Form.Item label="Rate" name="customRate"><Input addonAfter="%" type={"number"}/></Form.Item>
          }}
        </Form.Item>
        <Form.Item rules={[{required: true, message: 'Please enter codes'}]}
                   label="Codes"
                   name="codesText">
          <Input.TextArea/>
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Submit </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default Submit;
