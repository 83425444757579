import { Spin } from 'antd';
import b_ from 'b_';
import React from 'react';
import cx from '../../Utils/cx';
import './styles.scss';

const b = b_.lock('LoadingBlock');

function LoadingBlock({ blockRef, children, loading, noMinHeight, className, contentClassName }) {
  return (
    <div className={cx(b({ noMinHeight }), className)}>
      <div ref={blockRef} className={cx(b('content', { loading }), contentClassName)}>
        {children}
      </div>
      {loading && (
        <div className={b('spinContainer')}>
          <Spin className={b('spin')} />
        </div>
      )}
    </div>
  );
}

export default LoadingBlock;
