import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {LoadingOutlined} from "@ant-design/icons";
import {payoutRetry} from "../../../Reducers/payouts";

const useBoolean = frc.hooks.useBoolean;

function Retry({className, payoutId, afterUpdate, withOrderId}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.payouts.payoutRetry, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback((data) => {
    dispatch(
      payoutRetry({payoutId: payoutId, ...data})
    ).then(() => {
      message.success('Success!');
      close();
      form.resetFields()
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error('Failed to retry.');
    });
  }, [dispatch, payoutId, close]);

  return <>
    <Button className={className} type="link" size="small"
            onClick={open}>Retry</Button>
    <Drawer onClose={close} visible={isOpen} title={withOrderId ? "Retry" : "Confirm Retry"} placement="right"
            width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        {withOrderId && <Form.Item label="Exchange Order ID" name="orderId" rules={[{required: true}]}>
          <Input type="text"/>
        </Form.Item>
        }
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined/>}> Confirm </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default Retry;
