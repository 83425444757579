import {createAction, createReducer} from '@reduxjs/toolkit';
import frc from 'front-end-common';
import CodesService from "../Services/CodesService";

const {getDefaultHandler, getDefaultState} = frc.utils.reducerTools;

export const codesPageFetch = createAction('codes/page', (params) => ({
  payload: CodesService.getCodesList(params),
}))

export const validate = createAction('code/validate', (codeId) => ({
  payload: CodesService.validate(codeId),
}))

export const cancel = createAction('code/cancel', (codeId) => ({
  payload: CodesService.cancel(codeId),
}))

export const submit = createAction('codes/submit', (data) => ({
  payload: CodesService.submit(data),
}))

export const getSubmitPageData = createAction('codes/get-submit-page-data', () => ({
  payload: CodesService.getSubmitPageData(),
}))

const initState = {
  page: getDefaultState(),
  validate: getDefaultState(),
  cancel: getDefaultState(),
  submit: getDefaultState(),
  getSubmitPageData: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(codesPageFetch, 'page'),
  ...getDefaultHandler(validate, 'validate'),
  ...getDefaultHandler(cancel, 'cancel'),
  ...getDefaultHandler(submit, 'submit'),
  ...getDefaultHandler(getSubmitPageData, 'getSubmitPageData'),
});
